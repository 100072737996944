.root{
    border: 0px !important;
    border-radius: 0px !important;
}

.readaloudbutton{
    width: auto !important;
    height: 2rem !important;
    border: 0px !important;
    border-radius: 0px !important;
    padding: 10px !important;
}

.chip{
    display: flex; 
    align-items: center; 
    background-color: var(--mantine-color-blue-4); 
    border: 1px solid #ccc; 
    border-radius: 20px; 
    padding: 4px;
    font-size: 7px;
    font-weight: 700;
    color: #fff;
}