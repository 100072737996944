.center {
  width: 100%;
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center;
}
.wrapper {
  position: relative;
  margin-bottom: 30px;
  width: 50vw;
  height: 30vh;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.dropzone {
  border-width: 1px;
  padding-bottom: 50px;
  height: 100%;
  width: 100%;
}

.icon {
  color: light-dark(var(--mantine-color-gray-4), var(--mantine-color-dark-3));
}

.control {
  position: absolute !important;
  width: 250px !important;
  left: calc(50% - 125px);
  bottom: -20px;
  background-color: var(--mantine-color-blue-4) !important;
}
